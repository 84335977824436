var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"next-jendela@v2.55.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from '@sentry/tracing';
import { defaultIntegrations } from "@sentry/nextjs";

const SENTRY_DSN: string =
  process.env.SENTRY_DSN! || process.env.NEXT_PUBLIC_SENTRY_DSN!;

const SENTRY_ENV: string =
  process.env.SENTRY_ENVIRONMENT! || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT! || 'production'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN || "https://6af34b878ed34a7ba37b857a36904ab6@sentry.jendela360.com/15",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    integrations: [
      ...defaultIntegrations,
      // @ts-ignore
      new BrowserTracing({
        tracingOrigins: ["localhost", "api.jendela.test", "hq.jendela360.com", "api.jendela360.com"],
        shouldCreateSpanForRequest: (url: string) => {
          // Do not create spans for outgoing requests to a `/health/` endpoint
          return !url.match(/\/health\/?$/);
        },
      }),
    ],
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    // @ts-ignore
    // tracesSampler: samplingContext => {
    //   // always inherit
    //   if (samplingContext.parentSampled !== undefined) {
    //     return samplingContext.parentSampled
    //   }
    // },
    debug: SENTRY_ENV === 'local' ? true : false
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
